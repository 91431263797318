import styled from 'styled-components';
import { rem } from 'polished';
import { CharacteristicsTemplate } from './CharacteristicsTemplate';
import { breakpoints } from '../../../styles/theme';

export const CharacteristicsStyled = styled(CharacteristicsTemplate)`
    max-width: ${rem(1520)};
    margin: ${rem(45)} auto;
    padding: 0 ${rem(20)};

    .Collapsible {
        width: 100%;
    }

    .Collapsible__trigger {
        &.is-open {
            svg {
                transform: rotate(-180deg);
            }
        }

        &.is-closed {
            .label {
                padding-bottom: ${rem(15)};
            }
        }
    }

    .sectionSubTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.withSpace {
            padding-bottom: ${rem(10)};
        }
    }

    .primaryTechs {
        margin: ${rem(20)} 0;
        padding: ${rem(45)} ${rem(20)} 0 ${rem(20)};
        display: flex;
        flex-wrap: wrap;

        &.customPadding {
            padding: ${rem(25)};
            margin-bottom: 0;
        }

        @media (min-width: ${breakpoints.sm}px) {
            flex-direction: row;
            padding: ${rem(45)} ${rem(35)} 0 ${rem(35)};

            &.withSpaces {
                justify-content: space-between;
            }
        }

        &_section {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 50%;
            gap: ${rem(20)};
            padding-bottom: ${rem(45)};

            &:not(:last-child) {
                margin-right: ${rem(40)};
            }

            @media (min-width: ${breakpoints.sm}px) {
                width: auto;
            }

            &.fuel,
            &.energy {
                width: 50%;

                @media (min-width: ${breakpoints.sm}px) {
                    width: auto;
                    margin-bottom: 0;
                }
            }

            &.fuel {
                svg {
                    width: ${rem(25)};
                    height: ${rem(25)};
                    path {
                        stroke: ${({ theme }) => theme.colors.black};
                    }
                }
            }

            &.energy {
                margin-right: ${rem(80)};

                svg {
                    width: ${rem(35)};
                    height: ${rem(35)};
                    margin-top: ${rem(-5)};
                }
            }

            &__detail {
                display: flex;
                flex-direction: column;
                gap: ${rem(15)};
            }
        }
    }

    .secondaryTechs {
        &_sections {
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.sm}px) {
                flex-direction: row;
                justify-content: space-between;
            }

            &__section {
                margin: ${rem(20)} 0;

                @media (min-width: ${breakpoints.sm}px) {
                    width: 50%;
                    margin: 0;
                }

                ul {
                    padding-left: ${rem(20)};

                    li {
                        padding-bottom: ${rem(10)};
                    }
                }
            }
        }
    }

    .detailTechs {
        &_sections {
            display: flex;
            flex-direction: column;
            gap: ${rem(20)};

            &__section {
                width: 100%;
                margin-bottom: ${rem(15)};

                &___value {
                    display: flex;
                    flex-direction: column;
                    gap: ${rem(10)};
                    padding-top: ${rem(20)};

                    img {
                        width: 50%;
                    }

                    @media (min-width: ${breakpoints.sm}px) {
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 0;

                        span {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .characteristicsTechs {
        margin-bottom: ${rem(20)};
        &_sections {
            &__section {
                break-inside: avoid-column;
                padding-bottom: ${rem(20)};

                &.withSpace {
                    padding: ${rem(15)} 0;
                }

                &___value {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-top: ${rem(15)};
                }

                &___image {
                    width: 100%;
                    cursor: pointer;
                    margin-top: ${rem(20)};
                }

                ul {
                    padding: ${rem(10)} 0 0 ${rem(20)};
                    margin: 0;

                    li {
                        padding-bottom: ${rem(10)};
                    }
                }

                svg {
                    margin-left: ${rem(8)};
                    margin-bottom: ${rem(-2)};
                }
            }
        }

        @media (min-width: ${breakpoints.sm}px) {
            columns: 2;
            column-gap: 10%;
        }
    }

    .imageViewer {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999;
        background-color: rgba(0, 0, 0, 0.5);

        &_image {
            height: auto;
            width: 90%;
            position: relative;

            &.withPointer {
                cursor: pointer;
            }

            img {
                width: 100%;
                height: 100%;
            }

            button {
                position: absolute;
                right: ${rem(20)};
                top: ${rem(20)};
                font-size: ${rem(20)};
                background: none;
                border: none;
            }
        }
    }
`;
