import styled from 'styled-components';
import { css, breakpoints } from '../../../../styles/theme';
import { WallboxSummaryStyled } from '../../WallboxSummaryStyled';
import { rem } from 'polished';

export const WallboxSummaryOV = styled(WallboxSummaryStyled)`
    .description {
        &_callback {
            max-width: ${rem(320)};
        }
    }
`;
