import { pushTriggerToPosthog } from './usePosthog';
import { useFeatureSwitch } from './useFeatureSwitch';
import useCustomRouter from './useCustomRouter';
import routes from 'src/constants/routes';
import { Pages } from 'src/types/pages';

export const useExperiment = () => {
    const { isPosthogEnabled } = useFeatureSwitch();
    const router = useCustomRouter();

    const pushTrigger = (
        trigger: string,
        page: string,
        action?: string | null,
        customer?: string | null,
        position?: string | null,
        payment?: string | null,
        vehicle?: object | null
    ) => {
        if (isPosthogEnabled) {
            pushTriggerToPosthog({ trigger, page, action, customer, position, payment, vehicle });
        }
    };

    const getPageNameByUrl = (): string => {
        const currentPath = router.asPath;

        switch (true) {
            case currentPath.includes(routes.SELECTOR):
                return Pages.trim;
            case currentPath.includes(routes.BASKET):
                return Pages.basket;
            case currentPath.includes(routes.CAR):
                return Pages.configurator;
            case currentPath.includes(routes.DEALER):
                return Pages.dealer;
            case currentPath.includes(routes.CHECKOUT_DELIVERY):
            case currentPath.includes(routes.CHECKOUT_MY_DETAILS):
            case currentPath.includes(routes.CHECKOUT_ORDER_REVIEW):
            case currentPath.includes(routes.CHECKOUT_PRE_ORDER):
            case currentPath.includes(routes.CHECKOUT_CONFIRMATION):
                return Pages.checkout;
            case currentPath.includes(routes.SUMMARY):
                return Pages.summary;
            default:
                return 'N/A';
        }
    };

    return {
        pushTrigger,
        getPageNameByUrl,
    };
};
