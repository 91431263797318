import styled from 'styled-components';
import { breakpoints, css } from '../../styles/theme';
import WallboxSelect from './WallboxSummaryTemplate';
import { rem } from 'polished';

export const WallboxSummaryStyled = styled(WallboxSelect)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(40)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
        align-items: unset;
    }

    .detail {
        max-width: ${rem(250)};
        padding: ${rem(15)};
        border: 2px solid ${({ theme }) => theme.colors.grey7};

        img {
            width: 100%;
        }

        &_rows {
            &__title {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                line-height: ${rem(20)};
                margin-bottom: ${rem(4)};
                padding-top: ${rem(15)};
            }

            &__description {
                margin-bottom: ${rem(10)};
                font-size: ${({ theme }) => theme.fontSizes.textSubMedium};
            }

            &__price {
                font-size: ${({ theme }) => theme.fontSizes.textSubMedium};
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-size: ${({ theme }) => theme.fontSizes.textMedium};
            line-height: ${rem(20)};
            margin-top: 0;
        }

        &_callback {
            display: inline-flex;
            align-items: center;
            gap: ${rem(15)};
            color: #018210;
            background: #00ff001a;
            border-radius: ${rem(20)};
            padding: ${rem(12)} ${rem(18)};
            margin: ${rem(15)} 0;

            svg {
                width: ${rem(16)};
                height: ${rem(16)};
                margin-bottom: ${rem(-4)};

                path {
                    fill: #018210;
                }
            }
        }
    }
`;
