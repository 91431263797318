import React from 'react';
import { BRAND, isMarketGB } from 'src/constants/main';
import { WallboxSummaryAC } from './AC/WallboxSummary.AC';
import { WallboxSummaryOV } from './OV/WallboxSummary.OV';
import { WallboxSummaryAP } from './AP/WallboxSummary.AP';
import { WallboxSummaryDS } from './DS/WallboxSummary.DS';
import { WallboxSummaryOV as WallboxSummaryOV_GB } from './OV/GB/WallboxSummary.OV';
import { WallboxSummaryStyled } from './WallboxSummaryStyled';
import { SC } from 'src/styles/theme';

export type WallboxSummaryProps = SC & {};

const WallboxSummary = (props: WallboxSummaryProps) => {
    if (BRAND === 'AC') return <WallboxSummaryAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <WallboxSummaryOV_GB {...props} /> : <WallboxSummaryOV {...props} />;
    if (BRAND === 'AP') return <WallboxSummaryAP {...props} />;
    if (BRAND === 'DS') return <WallboxSummaryDS {...props} />;
    return <WallboxSummaryStyled {...props} />;
};

export default WallboxSummary;
