import React, { FC } from 'react';
import useTranslations from '@hooks/useTranslations';
import Icon, { Icons } from '@components/Icon';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { isBrandAC, WALLBOX_TYPE } from '../../constants/main';
import Link from '@components/Link';
import { ITrimAttribute } from '../../interfaces/Car';
import { WallboxSummaryProps } from '@components/WallboxSummary/index';
import { IconTemplate } from '@components/Icon/IconTemplate';

const WallboxSummaryTemplate: FC<WallboxSummaryProps> = ({ className }) => {
    const { t } = useTranslations();

    const { currentDeal } = useCarDetailsDuck();

    const detailData = currentDeal?.fullProductConfiguration?.trimAttributes?.find(
        (attr: ITrimAttribute) => attr.type === WALLBOX_TYPE && attr.attributeCode === 'description'
    );
    const priceData = currentDeal?.fullProductConfiguration?.trimAttributes?.find(
        (attr: ITrimAttribute) => attr.type === WALLBOX_TYPE && attr.attributeCode === 'price'
    );

    return (
        <div className={className}>
            <div className="detail">
                <img className="detail_image" src={detailData?.imageURL} alt={detailData?.title} />
                <div className="detail_rows">
                    <div className="detail_rows__title">{detailData?.title}</div>
                    <div className="detail_rows__description">{detailData?.description}</div>
                    <div className="detail_rows__price">
                        {priceData?.description} {priceData?.title}
                    </div>
                </div>
            </div>
            <div className="description">
                <p dangerouslySetInnerHTML={{ __html: t('basket.wallbox.description') }} />
                <div className="description_callback">
                    <IconTemplate name={Icons.Phone} />
                    <span>{t('basket.wallbox.callback.label')}</span>
                </div>
                <Link
                    label={t('basket.wallbox.link.label')}
                    onClick={() => window.open(t('basket.wallbox.link.href'), '_blank')}
                    primary={!isBrandAC}
                    secondary={isBrandAC}
                    marginTop={15}
                    withArrowIcon
                    className="link"
                />
            </div>
        </div>
    );
};

export default WallboxSummaryTemplate;
