import { FINANCE_JOURNEY, CASH_JOURNEY } from 'src/types/journey';
import { ICarDetail } from 'src/redux/carDetails/carDetails.duck.interface';
import { IDeal } from 'src/interfaces/Car';
import { createDeal } from '@utils/Car.utils';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useExternalCheckout } from '@hooks/useExternalCheckout';
import { useJourneyType } from '@hooks/useJourneyType';
import { CASH, FINANCE } from 'src/services/filters/filters';
import { getGeolocationExtraFileds } from '@utils/Deal.utils';
import { FinanceStatuses } from 'src/types/financeStatuses';
import CarDetailsDuck from 'src/redux/carDetails/carDetails.duck';
import FinanceWidgetDuck from 'src/redux/financeWidget/financeWidget.duck';
import { Redux } from 'src/redux/redux.interface';
import { isEqual } from 'lodash';
import { DealService } from '../services';
import { IConfiguratorOption } from '../redux/configurator/configurator.duck.interface';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useConfiguratorDuck } from '@hooks/useConfiguratorDuck';
import { useRouter } from 'next/router';
import Routes from '../constants/routes';
import { PxVersion } from 'src/constants/main';
import { getFeatureSwitch } from '@utils/featureSwitches.utils';
import { FEATURES_LIST, FeatureSwitchContext, IFeatureSwitchContext } from 'src/context/featureSwitchApp';
import { IExperimentalFeatureFlagContext } from '@utils/experiments/@types';

export function mapDealToExtraFields(
    carDetails: ICarDetail,
    currentDeal: IDeal,
    financeWidgetParameters: any,
    externalCheckoutEnabled: boolean,
    paymentJourneyType: typeof CASH | typeof FINANCE,
    context: IFeatureSwitchContext & IExperimentalFeatureFlagContext,
    currentPathname?: string,
) {
    const configId = carDetails?._id ?? carDetails?.externalId;
    const samePaymentJourney =
        !currentDeal?.extraFields?.hasSelectedPaymentJourney ||
        (paymentJourneyType === FINANCE && currentDeal?.journeyType === FINANCE_JOURNEY) ||
        (paymentJourneyType === CASH && currentDeal?.journeyType === CASH_JOURNEY);
    const sameDealExists = currentDeal?.configuration === configId && samePaymentJourney;
    const pxVersion = getFeatureSwitch(FEATURES_LIST.FEATURE_SWITCH_PX_VERSION, context);

    const extra = {
        financeGatewayParameters: financeWidgetParameters ?? {},
        useExternalCheckout: externalCheckoutEnabled,
        ...getGeolocationExtraFileds(currentDeal),
        ...(currentDeal?.extraFields?.hasSelectedPaymentJourney && {
            hasSelectedPaymentJourney: currentDeal?.extraFields.hasSelectedPaymentJourney,
        }),
        ...(currentDeal?.extraFields?.selectedFeesValue && {
            selectedFeesValue: currentDeal?.extraFields.selectedFeesValue,
        }),
        ...(currentDeal?.extraFields?.selectedPxValue && {
            selectedFeesValue: currentDeal?.extraFields.selectedPxValue,
        }),
        ...(currentPathname?.includes(Routes.CAR) && pxVersion === PxVersion.v2 && { PxV2Enabled: true }),
    };

    return {
        extra,
        sameDealExists,
        samePaymentJourney,
        configId,
    };
}

export const useCore = () => {
    const carDetails: ICarDetail = useSelector((state: Redux) => CarDetailsDuck.getCurrentCarDetails(state));
    const { paymentJourneyType, carJourney } = useJourneyType();
    const financeWidgetParameters = useSelector((state: Redux) => FinanceWidgetDuck.getFinanceWidgetParameters(state));
    const { externalCheckoutEnabled } = useExternalCheckout();
    const currentDeal: IDeal = useSelector((state: Redux) => CarDetailsDuck.getCurrentDeal(state));
    const dispatch = useDispatch();
    const router = useRouter();
    const context = useContext(FeatureSwitchContext);

    const fields = mapDealToExtraFields(
        carDetails,
        currentDeal,
        financeWidgetParameters,
        externalCheckoutEnabled,
        paymentJourneyType,
        context,
        router?.pathname,
    );

    return {
        carDetails,
        paymentJourneyType,
        carJourney,
        financeWidgetParameters,
        externalCheckoutEnabled,
        currentDeal,
        dispatch,
        ...fields,
    };
};

export const useDealCreate = (): {
    dealCreate: (status: FinanceStatuses, withOptionsUpdate?: boolean) => Promise<IDeal>;
} => {
    const {
        carDetails,
        paymentJourneyType,
        financeWidgetParameters,
        externalCheckoutEnabled,
        currentDeal,
        configId,
        carJourney,
        dispatch,
        sameDealExists,
        extra,
    } = useCore();

    const { carOptions } = useConfiguratorDuck();

    return {
        dealCreate: useCallback(
            async (status: FinanceStatuses, withOptionsUpdate?: boolean) => {
                try {
                    if (sameDealExists) {
                        if (
                            withOptionsUpdate &&
                            currentDeal?.fullProductConfiguration &&
                            !isEqual(carDetails?.options, currentDeal?.fullProductConfiguration?.options)
                        ) {
                            const { data } = await DealService.updateDeal(
                                {
                                    selectedOptions: carDetails?.options?.map((option: IConfiguratorOption) => {
                                        const optionWithUpsellCondition = carOptions?.find(
                                            (availableOption) => availableOption.id === option.id
                                        );

                                        if (optionWithUpsellCondition.upsell)
                                            return { ...optionWithUpsellCondition, upsellSelectedByCustomer: true };

                                        return optionWithUpsellCondition;
                                    }),
                                },
                                getSessionIdCookie(),
                                true
                            );
                            dispatch(CarDetailsDuck.setCurrentDeal(data?.deal));
                            return data?.deal;
                        }

                        return currentDeal;
                    }
                    const { deal, financeQuote } = await createDeal(carDetails, paymentJourneyType, extra, status);

                    batch(() => {
                        dispatch(CarDetailsDuck.setCurrentDealAndCarDetail(deal));
                        dispatch(FinanceWidgetDuck.setFinanceQuote(financeQuote));
                    });
                    return deal;
                } catch {
                    return currentDeal;
                }
            },
            [configId, sameDealExists, paymentJourneyType, carJourney, financeWidgetParameters, externalCheckoutEnabled]
        ),
    };
};

export const useCreateDeal: () => () => Promise<IDeal> = () => {
    const {
        carDetails,
        paymentJourneyType,
        carJourney,
        financeWidgetParameters,
        externalCheckoutEnabled,
        currentDeal,
        dispatch,
        configId,
        sameDealExists,
        extra,
    } = useCore();

    return useCallback(async () => {
        if (sameDealExists) {
            return currentDeal;
        }
        const { deal } = await createDeal(carDetails, paymentJourneyType, extra);
        dispatch(CarDetailsDuck.setCurrentDealAndCarDetail(deal));
        return deal;
    }, [configId, sameDealExists, paymentJourneyType, carJourney, financeWidgetParameters, externalCheckoutEnabled]);
};
