import React, { FunctionComponent, memo, useCallback, useState } from 'react';

import CheckoutCarDetails from './CheckoutCarDetails/CheckoutCarDetails';
import { Props } from '.';
import useTranslations from '@hooks/useTranslations';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useIsMyAccount } from '@hooks/useIsMyAccount';
import { usePrices } from '@hooks/usePriceCalculator';
import { connect, useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import routes from '../../constants/routes';
import useCustomRouter from '@hooks/useCustomRouter';
import { MySavedDealJourneyType } from '../../redux/user/user.duck.interface';
import { dealStatuses } from '../../pages/checkout/my-details';
import { getSessionIdCookie } from '@utils/Session.utils';
import { dealerApi } from '../../services';
import GlobalDuck from '../../redux/global/global.duck';
import { getInitalJourneyType } from '@utils/url.utils';
import DealerDuck from '../../redux/dealer/dealer.duck';
import UserDuck from '../../redux/user/user.duck';
import FinanceCalculatorService from '../../services/financeQuote/financePrices.service';
import FinanceWidgetDuck from '../../redux/financeWidget/financeWidget.duck';
import UserServices from '../../services/user/user.service';
import CarInfoHeader from './CarInfoHeader/CarInfoHeader';
import { constructPsaCheckoutUrl, getAgentDiscount } from '@utils/Deal.utils';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useUserDuck } from '@hooks/useUserDuck';
import { useExternalCheckout } from '@hooks/useExternalCheckout';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { DEAL_PRODUCT_TYPE } from 'src/constants/main';
import FilterDuck from 'src/redux/filters/filter.duck';

const CarMainInfoTemplate: FunctionComponent<Props> = memo(
    ({
        carConfigData,
        isPreOrder,
        financeQuote,
        existingLanguage,
        className,
        dispatch,
        dealer,
        dealerId,
        isExpired,
        offerEnd,
        isCheckout,
        isMyOrderPage,
        dealerDiscount,
        dealId,
        actionSetActiveDeal,
        comments,
        isParent = true,
        financeQuoteId,
        mopId,
        dealerPx,
        displayPx,
        journeyType,
        status,
        displayDealerInfo,
        order,
    }) => {
        const isMyAccountPage = useIsMyAccount();
        const router = useCustomRouter();
        const { t, hasValue }: any = useTranslations();

        const [isExtendedViewOpen, setIsExtendedViewOpen] = useState(false);

        const { currentDeal } = useCarDetailsDuck();

        // TODO: REFACTOR SUMMARY - checkout, my account
        // 1st passed journey type, 2nd order journey type, 3rd current deal journey type, 4th global payment journey
        const globalPaymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));
        journeyType = journeyType ?? order?.sibling?.journeyType ?? currentDeal?.journeyType ?? globalPaymentJourney;
        const isCash = journeyType === 'cash' || journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH;

        const isEC41Journey = currentDeal?.productType === DEAL_PRODUCT_TYPE.CHILD;

        const toggleExtendedView = useCallback(() => {
            setIsExtendedViewOpen(!isExtendedViewOpen);
            if (!isMyAccountPage) {
                setTimeout(
                    () =>
                        isExtendedViewOpen
                            ? dispatch(UIDuck.closeCharacteristics())
                            : dispatch(UIDuck.openCharacteristics({ data: {}, modalType: '' })),
                    0
                );
            }
        }, [dispatch, isExtendedViewOpen, isMyAccountPage]);

        const { title, exteriorColour, interiorColour, engine, gearbox, fuel, options, brand, accessories, fees } =
            carConfigData;

        const {
            cash: { totalPrice, totalBasicPriceWithoutVat, basePrice, components, summary },
        } = usePrices(carConfigData, false, dealerDiscount, true, false, isCash ? 'cash' : 'finance', isCash);

        const parsedDealerPX = dealerPx && JSON.parse(dealerPx);
        const pxDiscountPrice = parsedDealerPX
            ? (parsedDealerPX.prices.governmentContributionAmount
                  ? Number(parsedDealerPX.prices.governmentContributionAmount)
                  : 0) +
              (parsedDealerPX.prices.dealerBonusAmount ? Number(parsedDealerPX.prices.dealerBonusAmount) : 0) +
              (parsedDealerPX.prices.dealerValuation ? Number(parsedDealerPX.prices.dealerValuation) : 0)
            : 0;

        const hasLegalText1 = hasValue('checkout.dealer.legal.text1');
        const hasLegalText2 = hasValue('checkout.dealer.legal.text2');
        const hasLegalText3 = hasValue('checkout.dealer.legal.text3');
        const hasLegalText = isCash && displayPx && (hasLegalText1 || hasLegalText2 || hasLegalText3);

        const { isUserLoggedIn } = useUserDuck();
        const { externalCheckoutEnabled, checkoutUrl } = useExternalCheckout();
        const isFinanceJourneyPSACheckoutRedirectAllowed = useFeatureSwitchEnabled(
            FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_FINANCE_JOURNEY_PSA_CHECKOUT
        );

        const handleActivateDeal = async () => {
            dispatch(UIDuck.setLoading(5));
            const currentDeal = await actionSetActiveDeal(dealId);

            // if the useExternalCheckout key does not exists then the deal was made before Disengage Drivvn checkout bundle of FS
            // and then only cash deals and finance BR should redirect there
            const legacyExternalRedirect =
                !('useExternalCheckout' in currentDeal.data.extraFields) &&
                (currentDeal.data.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH ||
                    isFinanceJourneyPSACheckoutRedirectAllowed);
            // if EC41 offer, we don't flag those deals so just decide on the current FSs
            const isOfferAndExternalEnabled = !isParent && externalCheckoutEnabled;

            if (
                isOfferAndExternalEnabled ||
                legacyExternalRedirect ||
                currentDeal.data.extraFields.useExternalCheckout
            ) {
                window.location.href = constructPsaCheckoutUrl(checkoutUrl, mopId, isUserLoggedIn);
                return;
            }

            // Internal Drivvn checkout
            // Car journey
            const carJourney = getInitalJourneyType(currentDeal.data.fullProductConfiguration);
            dispatch(GlobalDuck.setCarJourney(carJourney));

            // Dealers
            const carConfiguration = currentDeal.data.fullProductConfiguration;
            const dealer =
                carConfiguration.stock || !!carConfiguration.dealers[0]
                    ? carConfiguration.dealers[0]
                    : (await dealerApi.getDealer(currentDeal.data.dealerId)).data;
            dispatch(DealerDuck.setDealer(dealer));

            // Finance
            if (currentDeal?.data?.financeQuoteId) {
                const { data: financeQuote } = await FinanceCalculatorService.getFinanceQuote(
                    currentDeal.data.financeQuoteId
                );
                dispatch(FinanceWidgetDuck.setFinanceQuote(financeQuote));
            }

            // User details
            const token = getSessionIdCookie();
            const userAddress = await UserServices.userAdress(token);
            const userDetails = await UserServices.userDetails(token);

            dispatch(UserDuck.setUserAddress(userAddress.data.address));
            dispatch(UserDuck.setUserDetails(userDetails.data));

            dispatch(UIDuck.setLoading(-5));

            if (
                [
                    dealStatuses.redirectedToLoanProvider,
                    dealStatuses.financeApproved,
                    dealStatuses.financeRejected,
                ].includes(status)
            ) {
                return await router.replace(routes.CHECKOUT_ORDER_REVIEW);
            }

            return await router.replace(routes.CHECKOUT_MY_DETAILS);
        };

        return (
            <div className={className}>
                <div className={`mainInfo ${isPreOrder ? 'pre-order' : ''}`}>
                    <CarInfoHeader
                        isCheckout={isCheckout}
                        isParent={isParent}
                        isMyOrderPage={isMyOrderPage}
                        isExpired={isExpired}
                        offerEnd={offerEnd}
                        carConfigData={carConfigData}
                        handleActivateDeal={handleActivateDeal}
                    />

                    <CheckoutCarDetails
                        className={className}
                        brand={brand}
                        displayDealerInfo={displayDealerInfo}
                        exteriorColour={exteriorColour.description || exteriorColour.title}
                        exteriorColourPrice={components.exteriorColorPrice}
                        engine={engine.description}
                        gearbox={gearbox.description}
                        fuel={fuel}
                        mainTitle={title}
                        interiorColour={interiorColour.description || interiorColour.title}
                        interiorColourPrice={components.interiorColorPrice}
                        finalPriceWithAccessories={isEC41Journey ? summary.finalPriceWithoutAccessories : totalPrice}
                        finalPriceWithoutVat={totalBasicPriceWithoutVat}
                        finalPriceWithAccessoriesAndDiscounts={
                            isEC41Journey ? summary.finalPriceWithAccessoriesAndDiscounts : totalPrice
                        }
                        basePrice={isEC41Journey ? summary.vehiclePrice : basePrice + getAgentDiscount(currentDeal)}
                        pxDiscountPrice={pxDiscountPrice}
                        toggleExtendedView={toggleExtendedView}
                        technicalFeaturesVisible={isExtendedViewOpen}
                        options={options}
                        financeQuote={financeQuote}
                        financeQuoteId={financeQuoteId}
                        existingLanguage={existingLanguage}
                        dealer={dealer}
                        dealerPx={dealerPx}
                        carDetail={carConfigData}
                        dealerId={dealerId}
                        accessories={accessories}
                        dealerDiscount={dealerDiscount}
                        fees={fees}
                        comments={comments}
                        isParent={isParent}
                        order={order}
                    />
                    {hasLegalText && (
                        <>
                            <div className="separatorLine" />
                            {hasLegalText1 && (
                                <div className="checkoutLegalText">{t('checkout.dealer.legal.text1')}</div>
                            )}
                            {hasLegalText2 && (
                                <div className="checkoutLegalText">{t('checkout.dealer.legal.text2')}</div>
                            )}
                            {hasLegalText3 && (
                                <div className="checkoutLegalText">{t('checkout.dealer.legal.text3')}</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
);
CarMainInfoTemplate.displayName = 'CarMainInfoTemplate';

const mapDispatchToProps = {
    actionSetActiveDeal: UserDuck.actionSetActiveDeal,
};

export default connect(null, mapDispatchToProps)(CarMainInfoTemplate);
