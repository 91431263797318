import styled from 'styled-components';
import { breakpoints, css } from 'src/styles/theme';
import { WallboxSummaryStyled } from '../WallboxSummaryStyled';
import { rem } from 'polished';

export const WallboxSummaryOV = styled(WallboxSummaryStyled)`
    .description {
        &_callback {
            max-width: ${rem(280)};
        }
    }
`;
